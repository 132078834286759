import service from '@/utils/service'

//获取列表
export function getCustomerList(params = {}){
  return service({
    url: `customer/get-list`,
    method: 'get',
    params
  })
}

// 修改状态
export function editTagStatus(params = {}) {
  return service({
    url: `customer/edit-tag-status`,
    method: 'get',
    params
  })
}

//同步客户
export function syncCustomer(params = {}) {
  return service({
    url: `customer/sync`,
    method: 'get',
    params
  })
}
