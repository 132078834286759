export const userModule = {
	namespaced: true, // 命名空间
	state: {
		userinfo: {
			router: [],
		}, //登陆用户信息
		newUserInfo: null,
	},
	getters: {},
	mutations: {
		setUserInfo: (
			state: {
				newUserInfo: any;
				userinfo: any;
			},
			info: any
		) => {
			state.userinfo = info;
			state.newUserInfo = info;
		},
	},
	actions: {},
	modules: {},
};
