import { messageError } from '@/utils/message';
import { getSupplierList } from '@/api/supplier';

export const supplierModule = {
  namespaced:true ,// 命名空间

  state: {
    supplierList: [], 
  },

  getters: {},

  mutations: {
    setSupplierList: (state: { supplierList: any; }, data: any) => {
      state.supplierList = data
    },
  },

  actions: {
    getSupplierData: ({commit}:any) => {
      return new Promise(resolve => {
        try {
          getSupplierList({ limit: 9999,is_concise:1 }).then((res)=>{
            const { data } = res;
            commit('setSupplierList', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
  },
  
  modules: {},
}