import { getGroupList } from '@/api/materialGroup';
import { messageError } from '@/utils/message';

export const materialGroupModule = {
	namespaced: true, // 命名空间

	state: {
		GroupList: [
			{
				mg_id: 0,
				mg_name_zh: '请选择分类',
				children: [],
			},
		],
		GroupList2: [
			{
				mg_id: 0,
				mg_name_zh: '请选择分类',
				children: [],
			},
		],
	},

	getters: {},

	mutations: {
		//设置获取物料分类
		setGroupList: (state: { GroupList: any }, list: any) => {
			console.log('state.GroupList', state.GroupList);
			state.GroupList = list;
		},
		setGroupList2: (state: { GroupList2: any }, list: any) => {
			console.log('state.GroupList2', state.GroupList2);
			state.GroupList2 = list;
		},
	},

	actions: {
		getGroupData: ({ commit }: any) => {
			//异步取配置信息
			return new Promise((resolve) => {
				try {
					//获取物料分类
					getGroupList({ is_concise: 1 })
						.then((res: any) => {
							const { data } = res;
							commit('setGroupList', data);
						})
						.catch(() => {
							//Loading.close();
						});
				} catch (error) {
					messageError(`服务端异常：${JSON.stringify(error)}`);
				}
			});
		},
		getGroupData2: ({ commit }: any) => {
			//异步取配置信息
			return new Promise((resolve) => {
				try {
					//获取物料分类
					getGroupList({ is_concise: 1, group_type: 1, is_lazy: 0 })
						.then((res: any) => {
							const { data } = res;
							commit('setGroupList2', data);
						})
						.catch(() => {
							//Loading.close();
						});
				} catch (error) {
					messageError(`服务端异常：${JSON.stringify(error)}`);
				}
			});
		},
	},

	modules: {},
};
