import service from '@/utils/service'

// 登录
export function login(data = {}) {
  return service({
    url: `user/login`,
    method: 'post',
    data
  })
}

// 登出
export function logout(params = {}) {
  return service({
    url: `user/logout`,
    method: 'get',
    params
  })
}

//获取用户信息
export function getUserinfo(params = {}){
  return service({
    url: `user/get-info`,
    method: 'get',
    params
  })
}

//获取用户列表
export function getUserList(params = {}){
  return service({
    url: `user/get-list`,
    method: 'get',
    params
  })
}

// 修改状态
export function editUserStatus(params = {}) {
  return service({
    url: `user/edit-status`,
    method: 'get',
    params
  })
}

// 添加or修改
export function saveUser(data:any = {}) {
  let url = `user/add`;
  if(data.au_user_id > 0){
    url = 'user/edit';
  }
  return service({
    url: url,
    method: 'post',
    data
  })
}

//删除用户
export function deleteUser(params = {}){
  return service({
    url: `user/delete`,
    method: 'get',
    params
  })
}

//修改密码
export function updateUserPassword(data:any = {}) {
  return service({
    url: `user/edit-password`,
    method: 'post',
    data
  })
}
//修改密码
export function updateEmailPassword(data:any = {}) {
  return service({
    url: `user/edit-email-password`,
    method: 'post',
    data
  })
}

//编辑数据规则
export function editRule(data:any = {}) {
  return service({
    url: `user/edit-rule`,
    method: 'post',
    data
  })
}

//设置全局供应商
export function selectSupplier(params = {}){
  return service({
    url: `user/select-supplier`,
    method: 'get',
    params
  })
}

//获取员工用户列表
export function getEmployeeList(params = {}){
  return service({
    url: `user/get-employee-list`,
    method: 'get',
    params
  })
}

//开通供应商帐号
export function addSupplierUser(data:any = {}) {
  return service({
    url: `user/add-supplier-user`,
    method: 'post',
    data
  })
}