import { ElLoading } from 'element-plus';
//默认加载
export function loading(className = '.view-box', text = 'Loading...') {
	console.log('loading');
	const loadingInstance = ElLoading.service({
		text: text,
		lock: true,
		background: 'rgba(255, 255, 255, 0.7)',
		fullscreen: false,
		target: className,
	});
	return loadingInstance;
}

//表格加载
export function formLoading(className = '.el-dialog .el-dialog__body', text = 'Loading...') {
	console.log('formLoading');
	const loadingInstance = ElLoading.service({
		text: text,
		lock: true,
		background: 'rgba(255, 255, 255, 0.7)',
		fullscreen: false,
		target: className,
	});
	return loadingInstance;
}

//通过ID加载
export function targetLoading(target: string, text = 'Loading...') {
	console.log('targetLoading');
	const loadingInstance = ElLoading.service({
		text: text,
		lock: true,
		background: 'rgba(255, 255, 255, 0.7)',
		fullscreen: false,
		target: target,
	});
	return loadingInstance;
}

//表格加载
export function tableLoading(className = '.page-middle', text = 'Loading...') {
	console.log('tableLoading');
	const loadingInstance = ElLoading.service({
		text: text,
		lock: true,
		background: 'rgba(255, 255, 255, 0.7)',
		fullscreen: false,
		target: className,
	});
	return loadingInstance;
}

//铺满全屏加载
export function fullscreenLoading(text = 'Loading...') {
	console.log('fullscreenLoading');
	const loadingInstance = ElLoading.service({
		text: text,
		lock: true,
		background: 'rgba(255, 255, 255, 0.7)',
		body: true,
	});
	setTimeout(() => {
		loadingInstance.close();
	}, 1000);
	return loadingInstance;
}
