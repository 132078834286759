export const materialModule = {
	namespaced: true, // 命名空间
	state: {
		materialList: [],
	},
	getters: {
		getMaterialList: (state: { materialList: any }) => state.materialList,
	},
	mutations: {
		setMaterialList: (state: { materialList: any }, info: any) => {
			console.log('info', info);
			state.materialList = info;
		},
	},
	actions: {},
	modules: {},
};
