import i18n from '@/locale';
import { setLocalStorage, getLocalStorage } from '@/utils/localStorage';
export const i18nModule = {
	namespaced: true, // 命名空间
	state: {
		locale: getLocalStorage('lang') || 'zhCn',
	},
	getters: {},
	mutations: {
		setLocale: (state: any, locale: any) => {
			console.log('state', state);
			state.locale = locale;
			setLocalStorage('lang', locale);
			i18n.global.locale.value = locale;
		},
	},
	actions: {},
	modules: {},
};
